import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
// 引入工具函數fn
import Fn from "../util/fn"
//window.Fn = Fn
import store from '../store/index' // 引入 store.js

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/IndexView.vue'),
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('../views/PayView.vue'),
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('../views/SuccessView.vue'),
  },
  {
    path: '/fail',
    name: 'fail',
    component: () => import('../views/FailView.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

const checkAllowLang = function (lang){
  let langList = store.state.langList;
  lang = (lang + '').toLowerCase();
  for (let i in langList){
    if(lang == langList[i].value){
        return true;
    }
  }
  return false;
}

// 在路由守卫中检查token
router.beforeEach((to, from, next) => {
  let params = Fn.getURLparams(true)
  if(params.lang && checkAllowLang(params.lang)){
    sessionStorage.setItem("lang",params.lang);
  }
  /*let isSltedGame = Fn.isEmpty(sessionStorage.getItem("isSltedGame"))
  if (Fn.isEmpty(params.game_id) && isSltedGame) {
    sessionStorage.setItem("isSltedGame", false);
  } else if(!Fn.isEmpty(params.game_id) && isSltedGame) {
    sessionStorage.setItem("isSltedGame", true);
  }*/
  let isSltedGame = Fn.isEmpty(sessionStorage.getItem("isSltedGame"))
  if(Fn.isPositiveInt(params.game_id)){
    sessionStorage.setItem("isSltedGame", true);
    localStorage.setItem("default_game",params.game_id)
  }else if(isSltedGame){
    sessionStorage.setItem("isSltedGame", false);
  }

  if (to.path == "/") {
    next('/home')
  } else if (Fn.inArray(to.path, ['/index']) > -1) {
    next()
  } else {
    let token = localStorage.getItem('wtoken')
    if (to.path === '/login' && token) {
      next('/home')
    } else if (to.path !== '/login' && !token) {
      next('/login') // 如果不是登录页且token失效，重定向到登录页
    } else {
      next() // 继续正常的路由
    }
  }
})

export default router
